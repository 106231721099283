<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card>
      <v-card-title>
        <span>
          {{ 'Thông tin trang' }}
        </span>
        <v-spacer />
        <span class="add-evaluation--icon-close" @click="closeDialog()">
          <img src="/admin-static/close-fill-black.svg" alt="" />
        </span>
      </v-card-title>
      <v-divider class="mb-5" />
      <v-card-text>
        <BaseInput
          name="title"
          label="Tên trang"
          placeholder="Nhập tên trang"
          v-model="data.title"
          isRequiredTitle
        />
        <BaseInput name="url" label="url" v-model="data.url" hide-details disabled />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn outlined color="primary" class="my-1 text-none" hide-details dense @click="() => closeDialog()">{{
          'Huỷ'
        }}</v-btn>
        <v-spacer />
        <v-btn color="primary" class="my-1 text-none" @click="onAddNewPage" :disabled="isDisabled()">{{ 'Thêm' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import debounce from 'lodash-es/debounce'
export default {
  data() {
    return {
      dialog: false,
      data: {
        title: '',
        url: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      themeConfig: 'vendor/getThemeConfig',
    }),
  },
  methods: {
    isDisabled(){
      if(this.data.url) return false;
      return true
    },
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.refreshData()
      this.dialog = false
    },
    refreshData() {
      this.data = {
        title: '',
        url: '',
      }
    },
    validate() {
      if (!this.data.title.length) return false
      return true
    },
    debounce: debounce(function () {
      let count = null
      this.data.url = this.$utils.clearUnicode(this.data.title)
      const allUrl = Object.values(this.themeConfig)
        .map((v) => v.url)
        .filter(Boolean)
      allUrl.forEach((v) => {
        if (v === this.data.url) count++
      })
      if (count) this.data.url = `${this.data.url}-${count}`
    }, 1000),
    onAddNewPage() {
      if (this.validate()) {
        this.$emit('onAddNewPage', this.data)
        this.closeDialog()
        this.$message.success('Tạo trang thành công', 2000)
      } else return this.$message.error('Vui lòng điền đẩy đủ thông tin trang')
    },
  },
  watch: {
    'data.title': {
      handler() {
        this.debounce()
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.add-evaluation--icon-close {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #3e3e3c, $alpha: 0.1);
  }
}
</style>
