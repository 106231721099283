import axios from 'axios'
import auth from '@/store/modules/auth'
import vendor from '@/store/modules/vendor'
import Repository from '@/api/core'
const STUDENT_BASE_URL = process.env.VUE_APP_API_STUDENT
const VENDOR_BASE_URL = process.env.VUE_APP_API_VENDOR
import queryString from 'query-string'

const APIHelper = () => ({
  updateVendorCredential: (params, option) =>
    axios.put(
      VENDOR_BASE_URL + `/vendor-credential/${auth.state.auth.vendorId}`,
      params,
      {
        headers: {
          Authorization: auth.state.auth.accessToken,
        },
      },
      option
    ),
  fetchVendorCredential: () => {
    return Repository(VENDOR_BASE_URL + `/vendor-credential/${auth.state.auth.vendorId}`).fetch()
  },
  getAdmins: (params) => {
    return Repository(VENDOR_BASE_URL + `/user/clients`).fetch(params)
  },
  createAdmin: (params) => {
    return Repository(VENDOR_BASE_URL + `/user`).create(params)
  },
  deleteAdmin: (id) => {
    return Repository(VENDOR_BASE_URL + `/user`).delete(id)
  },
  getVendorIdFromOrigin: async () => {
    let cache = _loadVendorIdCache()
    if (cache) {
      return cache
    }
    const hostname = location.origin
    const payload = await axios.get(VENDOR_BASE_URL + `/vendor/get-vendor-from-origin?hostname=${hostname}`)
    if (!payload.data.vendorId) {
      throw new Error(`[GetVendorIdFromOrigin] Invalid payload '${JSON.stringify(payload)}'!`)
    }
    _saveVendorIdCache(payload.data)
    return payload.data
  },
  updateSettings: async (params,option) => {
    let newParams = convertCustomPage(params)
    let payload = newParams
    delete payload['vendorId']
    delete payload['id']
    let res = await axios.put(
      VENDOR_BASE_URL + `/vendor-setting/${vendor.state.vendor.vendorId}`,
      {...payload},
      {
        headers: {
          Authorization: auth.state.auth.accessToken,
          'ol-client-id': vendor.state.vendor.clientId,
          'ol-vendor-id': vendor.state.vendor.vendorId,
        },
      },
      option
    )
    let newSettings = await getCustomPage(res.data)
    return newSettings
  },
  getDataCustomPage: async (customPage) => {
    const query = queryString.stringify(customPage)
    let res = await Repository(VENDOR_BASE_URL + `/custom-page-data?${query}`).fetch()
    return res.data
  },
  updateCustomPage: (params) => {
    return axios.put(
      VENDOR_BASE_URL + `/custom-page-vendor-setting`,
      {...params},
      {
        headers: {
          Authorization: auth.state.auth.accessToken,
          'ol-client-id': vendor.state.vendor.clientId,
          'ol-vendor-id': vendor.state.vendor.vendorId,
        },
      }
    )
  },
  removeCustomPage: (id) => {
    return Repository(`${VENDOR_BASE_URL}/custom-page`).delete(id)
  },
  removeDataCustomPage: (id) => {
    return Repository(`${VENDOR_BASE_URL}/remove-data-custom-page`).delete(id)
  },
  getSettingsVendor: async (vendor) => {
    let vendorInfo = await axios.get(`${VENDOR_BASE_URL}/vendor/${vendor.vendorId}`)
    let vendorSettings = await axios.get(`${VENDOR_BASE_URL}/vendor-setting/${vendor.vendorId}`)
    let settings = vendorSettings.data
    let newSettings = await getCustomPage(settings)

    return {
      ...vendorInfo.data,
      settings: newSettings,
    }
  },
  statistic: (params) => Repository(STUDENT_BASE_URL + '/student/statistical').fetch(params),
})

function _loadVendorIdCache() {
  let data = _jsonParse(localStorage.getItem('ol-amplify-vendorId-from-origin'))
  if (
    data &&
    data.origin == window.location.origin &&
    data.payload &&
    typeof data.payload.vendorId === 'string' &&
    Date.now() < data.expiredAt
  ) {
    return data.payload
  }
  localStorage.removeItem('ol-amplify-vendorId-from-origin')
  return null
}

function _saveVendorIdCache(payload) {
  let data = {
    origin: window.location.origin,
    payload,
    expiredAt: Date.now() + 24 * 60 * 60 * 1000,
  }
  localStorage.setItem('ol-amplify-vendorId-from-origin', JSON.stringify(data))
}
async function getCustomPage(settings) {
  const excludedKeys = ['seo', 'themeData', 'viewData']
  const filteredData = Object.fromEntries(
    Object.entries(settings.learn.themeConfig || {}).filter(
      ([key, value]) => !excludedKeys.includes(key) && typeof value === 'string'
    )
  )
  let customPages = await Repository(VENDOR_BASE_URL + `/custom-page`).fetch()
  let data = {}
  Object.entries(filteredData).map(([key, value]) => {
    data[key] = customPages.data.find((i) => i.id === value)
  })
  let newSettings = {
    ...settings,
    learn: {
      ...settings.learn,
      themeConfig: {
        ...settings.learn.themeConfig,
        ...data,
      },
    },
  }
  return newSettings
}
function convertCustomPage(settings) {
  const excludedKeys = ['seo', 'themeData', 'viewData']
  const customPages = Object.fromEntries(
    Object.entries(settings.learn.themeConfig || {})
      .filter(([key, value]) => !excludedKeys.includes(key) && typeof value === 'object')
      .map(([key, value]) => [key, value._id])
  )
  let newSettings = {
    ...settings,
    learn: {
      ...settings.learn,
      themeConfig: {
        ...settings.learn.themeConfig,
        ...customPages,
      },
    },
  }
  return newSettings
}
function _jsonParse(str) {
  try {
    return JSON.parse(str)
  } catch (err) {
    return null
  }
}

export const Vendor = APIHelper()
export default {
  Vendor,
}
