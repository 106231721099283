var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "600px" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("span", [_vm._v(" " + _vm._s("Thông tin trang") + " ")]),
              _c("v-spacer"),
              _c(
                "span",
                {
                  staticClass: "add-evaluation--icon-close",
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "/admin-static/close-fill-black.svg",
                      alt: "",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mb-5" }),
          _c(
            "v-card-text",
            [
              _c("BaseInput", {
                attrs: {
                  name: "title",
                  label: "Tên trang",
                  placeholder: "Nhập tên trang",
                  isRequiredTitle: "",
                },
                model: {
                  value: _vm.data.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "title", $$v)
                  },
                  expression: "data.title",
                },
              }),
              _c("BaseInput", {
                attrs: {
                  name: "url",
                  label: "url",
                  "hide-details": "",
                  disabled: "",
                },
                model: {
                  value: _vm.data.url,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "url", $$v)
                  },
                  expression: "data.url",
                },
              }),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "my-1 text-none",
                  attrs: {
                    outlined: "",
                    color: "primary",
                    "hide-details": "",
                    dense: "",
                  },
                  on: { click: () => _vm.closeDialog() },
                },
                [_vm._v(_vm._s("Huỷ"))]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "my-1 text-none",
                  attrs: { color: "primary", disabled: _vm.isDisabled() },
                  on: { click: _vm.onAddNewPage },
                },
                [_vm._v(_vm._s("Thêm"))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }