var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _vm.isFillBlank
          ? _c("FillBlank", { attrs: { questionId: _vm.question.id } })
          : _vm._e(),
        _vm.isMultipleChoice
          ? _c("MultipleChoice", { attrs: { question: _vm.question } })
          : _vm._e(),
        _vm.isSingleChoice
          ? _c("SingleChoice", { attrs: { question: _vm.question } })
          : _vm._e(),
        _vm.isTrueFalseChoice
          ? _c("TrueFalseChoice", { attrs: { question: _vm.question } })
          : _vm._e(),
      ],
      1
    ),
    _vm.question.correct && !_vm.isTrueFalseChoice
      ? _c("div", [
          _c("span", { staticClass: "font-weight-medium" }, [
            _vm._v("Đáp án: "),
          ]),
          _c("span", {
            staticClass: "green--text",
            domProps: { innerHTML: _vm._s(_vm.correct) },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }