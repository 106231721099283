<template>
  <div>
    <div class="d-flex align-item-center" v-for="(answer, answerIndex) in question.answers" :key="answerIndex">
      <div style="display: inline-flex">{{ `${answer.label}`.toLowerCase() }}.</div>
      <div v-html="answer.value || ''" style="display: inline-flex; margin-left: 4px"></div>
      <span class="ml-2" :class="`ml-2 ${isCorrect(answer.key) === true ? 'green--text' : 'red--text'}`">{{
        convetText(studentAnswer(answer.key))
      }}</span>
    </div>
    <div class="mt-2">
      <span class="font-weight-medium">Đáp án:&nbsp;</span>
      <div v-for="(answer, answerIndex) in correctAnswer()" :key="answerIndex">
        <span>{{ answer.label.toLowerCase() }}-</span>
        <span class="green--text">{{ convetText(answer.value) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  props: {
    question: Object,
  },
  computed: {
    ...mapGetters({
      submission: 'pdfRender/getSubmission',
    }),
  },
  methods: {
    isCorrect(key) {
      if (this.submission.answers) {
        const studentAnswer = this.submission.answers[this.question.id]
        return studentAnswer ? studentAnswer.correct[key] === studentAnswer.answers[key] : ""
      }
    },
    convetText(text) {
      if (text == 'true' || text == true) {
        return 'Đ'
      }
      if (text == false) {
        return 'S'
      }
      return ''
    },
    studentAnswer(ansKey = '') {
      if (this.submission.answers) {
        const studentAnswer = this.submission.answers[this.question.id]
        return studentAnswer ? studentAnswer.answers[ansKey] : ''
      }
    },
    correctAnswer() {
      let question = this.submission.questions.find((item) => item.id === this.question.id)
      let correct = Object.values(this.question.answers).map((item) => {
        return {
          value: question.correct[item.key],
          label: item.label,
        }
      })
      return correct
    },
  },
}
</script>
