<template>
  <div class="navbar-spacing pa-5">
    <EditSiteInfo v-if="selected === 'common'" :viewData="viewData" :seoData="seoData" ref="common" />
    <EditPageInfo v-else :viewData="viewData" :ref="`${selected}`" :key="selected" />
    <EditSeo v-if="checkPageAllowSeo(selected)" :seoData="seoData" ref="seo" />
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import {clone, get, isEqual, differenceWith, assign, omit} from 'lodash-es'
import EditPageInfo from '@/modules/SiteBuilder/components/EditPageInfo'
import EditSiteInfo from '@/modules/SiteBuilder/components/EditSiteInfo'
import EditSeo from '@/modules/SiteBuilder/components/EditSeo'
export default {
  props: {
    selected: String,
    reloadIframe: Function,
  },
  computed: {
    ...mapGetters({
      viewHomePage: 'vendor/getViewHomepage',
      viewAboutUs: 'vendor/getViewAboutus',
      viewPrivacyPolicy: 'vendor/getViewPrivacyPolicy',
      viewRecruitment: 'vendor/getViewRecruitment',
      themeData: 'vendor/getThemedata',
      seo: 'vendor/getSeoData',
      getMessageError: 'sitebuilder/getMessageError',
      learnDomain: 'vendor/getLearningDomain',
      vendor: 'vendor/getVendor',
      authUser: 'auth/getAuthUser',
      pageInfo: 'customPage/getPage',
      themeConfig: 'vendor/getThemeConfig',
      settings: 'vendor/getSettings',
    }),
  },
  components: {
    EditSiteInfo,
    EditPageInfo,
    EditSeo,
  },
  data() {
    return {
      viewData: null,
      seoData: {},
    }
  },
  methods: {
    ...mapActions({
      updateSetting: 'vendor/updateSettingsVendor',
      updateCustomPage: 'vendor/updateCustomPage',
      setCreatePageSavingStatus: 'customPage/setCreatePageStatus',
      setPage: 'customPage/setPage',
      getDataCustomPage: 'vendor/getDataCustomPage',
    }),
    async setViewData() {
      if (this.selected === 'common') {
          this.viewData = clone(this.themeData, true)
        this.seoData = clone(this.seo, true)
      } else {
        this.viewData = []
        if(this.themeConfig[`${this.selected}`]){
          let res =await this.getDataCustomPage({customPage: this.themeConfig[`${this.selected}`].id})
          this.viewData = res.sort((a, b) => a.index - b.index)
        }
      }
      // if (this.selected === 'homepage') {
      //   this.viewData = clone(this.viewHomePage, true)
      // } else if (this.selected === 'about-us') {
      //   this.viewData = clone(this.viewAboutUs, true)
      // } else if (this.selected === 'privacy-policy') {
      //   this.viewData = clone(this.viewPrivacyPolicy, true)
      // } else if (this.selected === 'recruitment') {
      //   this.viewData = clone(this.viewRecruitment, true)
      // } else if (this.selected === 'common') {
      //   this.viewData = clone(this.themeData, true)
      //   this.seoData = clone(this.seo, true)
      // } else {
      //   const themeConfigFormatted = Object.values(this.themeConfig)
      //   const seoOtherPage = get(this.themeConfig, 'seo.otherPage', {})
      //   const index = themeConfigFormatted.findIndex((v) => this.selected.includes(v.url))
      //   if (index > -1) {
      //     this.viewData = clone(themeConfigFormatted[index].viewData || [], true)
      //     const seoOfThisPage = seoOtherPage[themeConfigFormatted[index].url]
      //     if (seoOfThisPage) this.seoData = seoOfThisPage
      //     return
      //   }
      //   this.viewData = []
      //   this.seoData = {}
      // }
    },
    async getCurrentViewData() {
      if (this.selected === 'common') {
        return this.themeData
      } else {
        let viewData = []
        if(this.themeConfig[`${this.selected}`]){
           let res =await this.getDataCustomPage({customPage: this.themeConfig[`${this.selected}`].id})
           viewData = res.sort((a, b) => a.index - b.index)
        }
        return viewData
      }
      // if (this.selected === 'homepage') {
      //   return this.viewHomePage
      // } else if (this.selected === 'about-us') {
      //   return this.viewAboutUs
      // } else if (this.selected === 'privacy-policy') {
      //   return this.viewPrivacyPolicy
      // } else if (this.selected === 'recruitment') {
      //   return this.viewRecruitment
      // } else if (this.selected === 'common') {
      //   return this.themeData
      // } else {
      //   const themeConfigFormatted = Object.values(this.themeConfig)
      //   const index = themeConfigFormatted.findIndex((v) => this.selected.includes(v.url))
      //   if (index > -1) return themeConfigFormatted[index].viewData || []
      // }
    },
    async checkChangeData() {
      const newData = await this.$refs[`${this.selected}`].getData()
      const oldData = await this.getCurrentViewData()
      const diff = await differenceWith(newData, oldData, this.compareData)
      if (!this.viewData || !diff.length) {
        return false
      } else {
        return true
      }
    },
    compareData(a, b) {
      const aWithoutUpdatedAt = omit(a, ['updatedAt'])
      const bWithoutUpdatedAt = omit(b, ['updatedAt'])
      return isEqual(aWithoutUpdatedAt, bWithoutUpdatedAt)
    },
    async onSave() {
      try {
        this.$message.loading('Đang lưu dữ liệu...', 30000)
        let seo = get(this.themeConfig, 'seo', {})
        const data = await this.$refs[`${this.selected}`].getData()
        let page = get(this.settings, `learn.themeConfig[${this.selected}]`, {})
        let currentPage = page._id ? page : this.pageInfo
        let dataSeo = {}
        let payload = {
          vendorSettingId: this.settings.id,
          viewData: data,
          isAddition: true,
          url: `${this.selected}`,
          title: currentPage.title || '',
          pageId: page._id || '',
        }
        if (this.selected === 'common') {
          let newSeo = await this.$refs[`common`].getData().seo
          assign(seo, newSeo)
          const excludedKeys = ['seo', 'themeData', 'viewData']
          const filteredData = Object.fromEntries(
            Object.entries(this.settings.learn.themeConfig || {})
              .filter(([key]) => !excludedKeys.includes(key))
              .map(([k, v]) => [k, v.id])
          )
          dataSeo = {themeData: data, seo, ...filteredData}
        }
        if (
          this.selected !== 'homepage' &&
          this.selected !== 'about-us' &&
          this.selected !== 'privacy-policy' &&
          this.selected !== 'recruitment' &&
          this.selected !== 'common'
        ) {
          let seoData = await this.$refs[`seo`].getData()
          const themeConfigFormatted = Object.values(this.themeConfig)
          const index = themeConfigFormatted.findIndex((v) => this.selected.includes(v.id))
          if (index > -1) this.setPage(themeConfigFormatted[index]) // check trang này đã tồn tại trong themeData chưa
          await this.$utils.delay(200)
          const otherPage = get(this.themeConfig, 'seo.otherPage', {})
          otherPage[currentPage.url] = {
            ...seoData,
            url: currentPage.url,
            pageName: currentPage.title,
            id: currentPage.id,
          }
          seo.otherPage = otherPage
          dataSeo = {seo}
        }
        if (this.getMessageError.length === 0) {
          if (this.selected !== 'common') {
            await this.updateCustomPage(payload)
          }
          await this.updateSetting(dataSeo)
          await this.setViewData()
          this.$message.success(this.$vuetify.lang.t('$vuetify.SITE_BUILDER.MSG_UPDATE_SUCCESS'))
          this.reloadIframe()
          this.setCreatePageSavingStatus(true)
        } else {
          this.$message.error(this.getMessageError)
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.data', null)
        if (errorMessage) return this.$message.error(errorMessage)
        this.$message.error('Đã có lỗi xảy ra, vui lòng thử lại')
      }
    },
    checkPageAllowSeo(selected) {
      if (
        selected === 'common' ||
        selected === 'homepage' ||
        selected === 'about-us' ||
        selected === 'privacy-policy' ||
        selected === 'recruitment'
      )
        return false
      return true
    },
  },
  watch: {
    selected: {
      async handler() {
        await this.setViewData()
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.navbar-spacing {
  margin-top: 72px;
}
</style>