import { Auth } from '@/api/repository/authRepository.js'
import Base64 from '@/helpers/encodeBase64.js'
import { cipher, decipher } from '@/helpers/cipher.js'
const AUTH_USER_STORAGE_NAME = 'ol-credentials-auth-user'
import get from 'lodash-es/get'

const state = {
	auth: null,
	authenticated: false,
}
const actions = {
	async initAuthen({ dispatch, rootState }) {
		const vendorId = rootState.vendor.vendor.vendorId
		let authUser = _loadAuthUser(vendorId)
		dispatch('validateAuthUser', { authUser, vendorId })
	},
	async signIn({ commit, dispatch, rootState }, payload) {
		const currentVendorId = rootState.vendor.vendor.vendorId
		if (!payload.email || !payload.password)
			throw new Error(
				`[Auth.SignIn] 'username' & 'password' && 'password' required!`
			)
		let body = {
			email: payload.email.toLowerCase(),
			password: Base64.encode(payload.password),
			vendorId: currentVendorId
		}
		const res = await Auth.signIn(body)
		localStorage.setItem('encode', Base64.encode(payload.password))
		_saveAuthUser(res, currentVendorId)
		dispatch('setAuth', res)
		commit('setAuthenticated', true)
		return res
	},
	async changePassword({ rootState, state }, payload) {
		const currentVendor = rootState.vendor.vendor
		const userInfoPassword = {
			userId: payload.userId,
			oldPassword: Base64.encode(payload.oldPassword),
			newPassword: Base64.encode(payload.newPassword)
		}
		return await Auth.changePassword(
			payload.type,
			userInfoPassword,
			currentVendor,
			state.auth.accessToken
		)
	},
	async signOut({ commit }) {
		commit('setAuth', null)
		commit('setAuthenticated', false)
		_removeAuthUser()
	},
	async validateAuthUser({ commit, dispatch }, payload) {
		const { authUser } = payload
		let now = Date.now()
		const accessTokenExpiredAt = get(authUser, 'accessTokenExpiredAt', null)
		if (!accessTokenExpiredAt || accessTokenExpiredAt < now) {
			_removeAuthUser()
		} else {
			dispatch('setAuth', authUser)
			commit('setAuthenticated', true)
		}
	},
	saveAuthuser(authUser) {
		_saveAuthUser(authUser, authUser.vendorId)
	},
	setAuth({ commit }, authuser) {
		commit('setAuth', authuser)
	}
}
const mutations = {
	setAuth(state, auth) {
		state.auth = auth
	},
	setAuthenticated(state, val) {
		state.authenticated = val
	}
}
const getters = {
	getAuthUser: state => state.auth,
	getAuthenticated: state => state.authenticated
}

// helpers

function _saveAuthUser(authUser, vendorId) {
	let authUserEncoded = cipher(vendorId)(
		JSON.stringify({ ...authUser, lastTimeUpdated: new Date().getTime() })
	)
	return window.localStorage.setItem(AUTH_USER_STORAGE_NAME, authUserEncoded)
}

function _removeAuthUser() {
	window.localStorage.removeItem('encode')
	return window.localStorage.removeItem(AUTH_USER_STORAGE_NAME)
}

function _loadAuthUser(vendorId) {
	let authUserEncoded = localStorage.getItem(AUTH_USER_STORAGE_NAME)
	try {
		return authUserEncoded
			? JSON.parse(decipher(vendorId)(authUserEncoded))
			: null
	} catch (err) {
		this.$message.error(err)
		return null
	}
}

export default {
	state,
	actions,
	getters,
	mutations,
	namespaced: true
}
