<template>
  <div v-show="isVisibility">
    <div id="pdf-renderer-exam" :key="rendererTime">
      <StudentInfo />
      <div class="question-container" v-for="(question, index) in flattenQuestions" :key="index">
        <div v-if="question.type === 'direct-fill-blank-group'">
          <QuestionDirectFillGroup :question="question" :index="index" />
        </div>
        <div v-else>
          <div class="question-overview">
            <div class="question-overview__parent" v-if="question.parentHtml">
              <div
                class="question-overview__description canvas-element-exam"
                v-for="(element, i) in question.parentHtml"
                :key="'parent' + i"
                :class="{'is-last': i === question.parentHtml.length - 1}"
              >
                <div v-if="i === 0" class="question-overview__parent-title">Câu hỏi nhóm</div>
                <div v-html="element"></div>
              </div>
            </div>
            <div
              class="question-overview__description canvas-element-exam"
              v-for="(element, i) in question.description"
              :key="'description' + i"
            >
              <div v-if="i === 0" class="question-overview__title">
                {{ $vuetify.lang.t('$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.LABEL_QUESTION') }}
                {{ question.index + 1 }}: {{ generateType(question.type) }}
              </div>
              <div v-html="element"></div>
            </div>
          </div>
          <div
            v-if="question.type !== 'group'"
            class="question-container__answer canvas-element-exam"
            :class="{'is-last': question.isLastQuestion}"
          >
            <Question :question="question" />
          </div>
        </div>
      </div>
    </div>
    <div id="pdf-renderer-temp-exam"></div>
    <div id="description"></div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {handlerQuestions} from '@/helpers/components/pdfRendererUtils.js'
import Question from '@/components/pdf-renderer/exam/Question'
import QuestionDirectFillGroup from '@/components/pdf-renderer/exam/QuestionDirectFillGroup'
import StudentInfo from '@/components/pdf-renderer/exam/StudentInfo'
export default {
  components: {Question, QuestionDirectFillGroup, StudentInfo},
  computed: {
    ...mapGetters({
      questions: 'pdfRender/getQuestions',
      rendererTime: 'pdfRender/getRenderedTime',
      isVisibility: 'pdfRender/getVisibilityExam',
    }),
  },
  data() {
    return {
      flattenQuestions: [],
    }
  },
  methods: {
    generateType(type) {
      switch (type) {
        case 'single-choice':
        case 'single-choice-child':
          return this.$vuetify.lang.t('$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.CHECKBOX_PICK_ONCE_CORRECT_ANSWER')
        case 'multiple-choice':
        case 'multiple-choice-child':
          return this.$vuetify.lang.t('$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.CHECKBOX_PICK_MULTIPLE_ANSWERS')
        case 'fill-blank':
        case 'fill-blank-child':
          return this.$vuetify.lang.t('$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.CHECKBOX_FILL_BLANK')
          case 'true-false-choice':
        case 'true-false-choice-child':
          return this.$vuetify.lang.t('$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.TRUE_FALSE_CHOICE')
        default:
          return ''
      }
    },
  },
  watch: {
    questions: {
      handler(questions) {
        this.flattenQuestions = handlerQuestions(questions)
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
@import './src/assets/scss/pdf-renderer/exam-renderer.scss';
</style>
