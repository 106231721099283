import {RepositoryFactory} from '@/api/factory/repositoryFactory'
import get from 'lodash-es/get'
const Affiliate = RepositoryFactory.get('affiliate')

const state = {
  affiliates: {},
  affiliateSetting: {},
  count: 0,
  listProducts: [],
}

const actions = {
  async fetchAffiliateSetting({commit}, params = {}) {
    const res = await Affiliate.fetch({
      ...params,
    })
    commit('setAffiliateSetting', get(res, 'data.0', []))
    commit('setListProducts', get(res, 'data.0.settings.detailSettings', []))
    return res.data
  },
  async applyAffiliate({commit}, data) {
    const res = await Affiliate.create(data)
    commit('appendAffiliate', res.data)
    return res.data
  },
}

const mutations = {
  setListProducts(state, products) {
    state.listProducts = products
  },
  pushItemToListProducts(state, product) {
    state.listProducts.push(product)
  },
  removeItemToListProducts(state, product) {
    state.listProducts = state.listProducts.filter((item) => item.id !== product.id)
  },
  appendAffiliate(state, affiliate) {
    state.affiliateSetting = affiliate
  },
  setAffiliateSetting(state, affiliate) {
    return (state.affiliateSetting = affiliate)
  },
}

const getters = {
  getAffiliateSetting: (state) => {
    return state.affiliateSetting
  },
  getListProducts: (state) => {
    return state.listProducts
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
