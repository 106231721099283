<template>
  <div>
    <ListSectionComponent
      v-if="mode === 'list'"
      @sort-end="(item) => onSortSection(item)"
      @onRemoveItem="(item) => onRemoveItem(item)"
      @onEditSection="(item) => onEditSection(item)"
      @onAddSection="mode = 'add'"
      :viewData="currentViewData"
      :isSlickDisabled="isSlickDisabled"
      :isMobile="isMobile"
    />
    <AddSectionComponent
      v-if="mode === 'add'"
      @backToListSection="mode = 'list'"
      @onAddSection="(item) => onAddSection(item)"
      :listSelectionsForBuilder="listSelectionsForBuilder"
      :isMobile="isMobile"
    />
    <EditSectionComponents
      v-else-if="mode === 'edit'"
      @backToListSection="mode = 'list'"
      @update="(data) => handleChangeData(data)"
      :initData="initData"
      :isMobile="isMobile"
    />
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import map from 'lodash-es/map'
import EditSectionComponents from '@/modules/SiteBuilder/components/EditSectionComponents'
import AddSectionComponent from '@/modules/SiteBuilder/components/AddSectionComponent'
import ListSectionComponent from '@/modules/SiteBuilder/components/ListSectionComponents'
export default {
  props: {
    viewData: Array,
    isSlickDisabled: Boolean,
    isMobile: Boolean,
  },
  components: {
    ListSectionComponent,
    AddSectionComponent,
    EditSectionComponents,
  },
  data() {
    return {
      mode: 'list',
      initData: null,
      currentViewData: null,
    }
  },
  computed: {
    listSelectionsForBuilder() {
      if (this.isMobile) {
        return map(this.$helpers.LIST_SELECTION_SCREENBUILDER, (selection) => {
          return {
            ...selection,
            children:
              selection.name === '$vuetify.SITE_BUILDER.SECTION.CARD_GROUP_NAME'
                ? selection.children.filter((s) => s.name !== 'event')
                : selection.children,
          }
        })
      } else {
        return map(this.$helpers.LIST_SELECTION_SITEBUILDER, (selection) => {
          return {
            ...selection,
            children:
              selection.name === '$vuetify.SITE_BUILDER.SECTION.MISC_GROUP_NAME'
                ? selection.children.filter((s) => s.name !== 'category')
                : selection.children,
          }
        })
      }
    },
  },
  methods: {
    ...mapActions({
      removeDataCustomPage: 'vendor/removeDataCustomPage',
    }),

    getData() {
      let viewData = this.currentViewData.map((item, index) => ({
        ...item,
        index,
      }))
      return viewData
    },
    onEditSection(idSection) {
      const detailSection = this.currentViewData.find((view) => view._id === idSection)
      this.initData = {
        ...detailSection,
        name: detailSection.name,
      }
      this.mode = 'edit'
    },
    handleChangeData(data) {
      this.currentViewData = this.currentViewData.map((view) => {
        if (view.name === data.name) {
          return data
        } else return view
      })
    },
    onAddSection(item) {
      this.currentViewData.push(item)
      this.initData = item
      this.mode = 'edit'
    },
    async onRemoveItem(item) {
      this.currentViewData = this.currentViewData.filter((view) => view.name !== item.name)
      if (item.id) {
        await this.removeDataCustomPage(item.id)
      }
    },
    onSortSection(item) {
      let cutItem = this.currentViewData.slice(item.oldIndex, item.oldIndex + 1)
      this.currentViewData.splice(item.oldIndex, 1)
      this.currentViewData.splice(item.newIndex, 0, cutItem[0])
    },
  },
  watch: {
    viewData: {
      handler(viewData) {
        this.currentViewData = viewData ? viewData : []
      },
      immediate: true,
    },
  },
}
</script>
