import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const UserTemp = RepositoryFactory.get('userTemp')
import dayjs from 'dayjs'
import pickBy from 'lodash-es/pickBy'

const state = {
  contacts: [],
  orders: [],
  totalContacts: 0,
  totalOrders: 0,
}

const actions = {
  async fetchContacts({commit}, params = {}) {
    const {
      page = 1,
      perPage = 10,
      searchString,
      timeRange,
    } = params

    const queryParams = {
      _skip: (page - 1) * perPage,
      _limit: perPage,
    }

    if (searchString) {
      queryParams.search = searchString
    }

    if (timeRange) {
      if (timeRange !== 'all') {
        const currentDay = new Date().getTime()
        const sameDayPreviousMonth = dayjs(currentDay).subtract(1, 'months').valueOf()
        queryParams.startDate = sameDayPreviousMonth
        queryParams.endDate = currentDay
      }
    }

    const cleanParams = pickBy(queryParams, (value) => value !== undefined && value !== null && value !== '')
    const res = await UserTemp.fetchContact(cleanParams)
    commit('setContacts', res.data || [])
  },
  async fetchOrders({commit}, params = {}) {
    const {
      page = 1,
      perPage = 10,
      status,
      searchString,
      timeRange,
      // thêm các filter khác nếu cần
    } = params

    // Xây dựng query params
    const queryParams = {
      _skip: (page - 1) * perPage,
      _limit: perPage,
    }

    // Thêm các điều kiện filter
    if (status) {
      queryParams.status = status
    }

    if (searchString) {
      queryParams.search = searchString
    }

    if (timeRange) {
      if (timeRange !== 'all') {
        const currentDay = new Date().getTime()
        const sameDayPreviousMonth = dayjs(currentDay).subtract(1, 'months').valueOf()
        queryParams.startDate = sameDayPreviousMonth
        queryParams.endDate = currentDay
      }
    }
    const cleanParams = pickBy(queryParams, (value) => value !== undefined && value !== null && value !== '')
    const res = await UserTemp.fetchOrder(cleanParams)
    commit('setOrders', res.data || [])
  },
  async updateOrder({commit}, params = {}) {
    const res = await UserTemp.update(params)
    commit('addOrUpdateOrder', res.data || {})
  },
  async countContacts({commit}, params = {}) {
    const cleanParams = pickBy(params, (value) => value !== undefined && value !== null && value !== '')
    const res = await UserTemp.countContact(cleanParams)
    commit('setTotalContacts', res.data || 0)
  },
  async countOrders({commit}, params = {}) {
    const cleanParams = pickBy(params, (value) => value !== undefined && value !== null && value !== '')
    const res = await UserTemp.countOrder(cleanParams)
    commit('setTotalOrders', res.data || 0)
  },
}

const mutations = {
  setContacts(state, contacts) {
    state.contacts = contacts.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
  },
  setOrders(state, orders) {
    state.orders = orders.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
  },
  addOrUpdateOrder(state, item) {
    if (item && item.id) {
      const foundIndex = state.orders.findIndex((order) => order.id === item.id)
      if (foundIndex > -1) {
        state.orders.splice(foundIndex, 1, item)
      } else {
        state.orders.unshift(item)
      }
    }
  },
  setTotalContacts(state, total) {
    state.totalContacts = total
  },
  setTotalOrders(state, total) {
    state.totalOrders = total
  },
}

const getters = {
  getContacts(state) {
    return state.contacts
  },
  getOrders(state) {
    return state.orders
  },
  getTotalContacts(state) {
    return state.totalContacts
  },
  getTotalOrders(state) {
    return state.totalOrders
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
