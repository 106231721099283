var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.question.answers, function (answer, answerIndex) {
        return _c(
          "div",
          { key: answerIndex, staticClass: "d-flex align-item-center" },
          [
            _c("div", { staticStyle: { display: "inline-flex" } }, [
              _vm._v(_vm._s(`${answer.label}`.toLowerCase()) + "."),
            ]),
            _c("div", {
              staticStyle: { display: "inline-flex", "margin-left": "4px" },
              domProps: { innerHTML: _vm._s(answer.value || "") },
            }),
            _c(
              "span",
              {
                staticClass: "ml-2",
                class: `ml-2 ${
                  _vm.isCorrect(answer.key) === true
                    ? "green--text"
                    : "red--text"
                }`,
              },
              [_vm._v(_vm._s(_vm.convetText(_vm.studentAnswer(answer.key))))]
            ),
          ]
        )
      }),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("span", { staticClass: "font-weight-medium" }, [
            _vm._v("Đáp án: "),
          ]),
          _vm._l(_vm.correctAnswer(), function (answer, answerIndex) {
            return _c("div", { key: answerIndex }, [
              _c("span", [_vm._v(_vm._s(answer.label.toLowerCase()) + "-")]),
              _c("span", { staticClass: "green--text" }, [
                _vm._v(_vm._s(_vm.convetText(answer.value))),
              ]),
            ])
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }