var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._v(
      " " +
        _vm._s(
          _vm.$vuetify.lang.t(
            "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.LABEL_ANSWER_THE_QUESTION"
          )
        ) +
        ": "
    ),
    _c("span", [
      _vm._v(" " + _vm._s(_vm.studentAnswer) + " "),
      _vm.submission.answers
        ? _c(
            "span",
            [
              !_vm.isCorrect
                ? _c("v-icon", { attrs: { size: "small", color: "red" } }, [
                    _vm._v("mdi-close"),
                  ])
                : _c("v-icon", { attrs: { size: "small", color: "green" } }, [
                    _vm._v("mdi-check"),
                  ]),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }