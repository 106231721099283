var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.mode === "list"
        ? _c("ListSectionComponent", {
            attrs: {
              viewData: _vm.currentViewData,
              isSlickDisabled: _vm.isSlickDisabled,
              isMobile: _vm.isMobile,
            },
            on: {
              "sort-end": (item) => _vm.onSortSection(item),
              onRemoveItem: (item) => _vm.onRemoveItem(item),
              onEditSection: (item) => _vm.onEditSection(item),
              onAddSection: function ($event) {
                _vm.mode = "add"
              },
            },
          })
        : _vm._e(),
      _vm.mode === "add"
        ? _c("AddSectionComponent", {
            attrs: {
              listSelectionsForBuilder: _vm.listSelectionsForBuilder,
              isMobile: _vm.isMobile,
            },
            on: {
              backToListSection: function ($event) {
                _vm.mode = "list"
              },
              onAddSection: (item) => _vm.onAddSection(item),
            },
          })
        : _vm.mode === "edit"
        ? _c("EditSectionComponents", {
            attrs: { initData: _vm.initData, isMobile: _vm.isMobile },
            on: {
              backToListSection: function ($event) {
                _vm.mode = "list"
              },
              update: (data) => _vm.handleChangeData(data),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }